import Vue from 'vue'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/es.json'
import i18n from '@/libs/i18n'
import axios from 'axios'

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule], // assign message
  })
})

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'Las contraseñas no coinciden',
})

extend('web', {
  params: ['target'],
  validate(value) {
    const regex = RegExp('(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*(\\?[;&a-z\\d%_.~+=-@]*)?(\\#[-a-z\\d_@]*)?$', 'i')
    return value.match(regex)
  },
  message: i18n.t('Web no valida'),
})

extend('validateCIF', {
  validate: value => {
    const regex = /^[a-zA-Z0-9]*$/
    return regex.test(value)
  },
  message: 'register.IncorrectCif',
})

const emailUnique = (value, args) => {
  return axios.post(`${process.env.VUE_APP_ROOT_API}/users/existemail`, { email: value, id: args.target })
    .then((response) => {
      return true
    })
    .catch(error => {
      return 'Debe ser un email único'
    })
}
extend('emailUnique', {
  params: ['target'],
  validate: emailUnique,
})

const macAddressUnique = (value, args) => {
  return axios.post(`${process.env.VUE_APP_ROOT_API}/assets/existmacaddress`, { mac_address: value, id: args.target })
    .then((response) => {
      return true
    })
    .catch(error => {
      return 'El Mac Address debe ser único'
    })
}
extend('macAddressUnique', {
  params: ['target'],
  validate: macAddressUnique,
})

extend('minAndMaxMedidas12000', {
  validate(value) {
    let res = false
    if (value >= 500 && value <= 12000) {
      res = true
    }
    return res
  },
  message: 'orders.out_range_error'
})

extend('max20', {
  validate(value) {
    let res = false
    if (value <= 20 ) {
      res = true
    }

    return res
  },
  message: 'orders.max_units'
})

extend('minAndMaxMedidas8000', {
  validate(value) {
    let res = false
    if (value >= 500 && value <= 8000) {
      res = true
    }
    return res
  },
  message: 'orders.out_range_error'
})
extend('minAndMaxMedidas10000', {
  validate(value) {
    let res = false
    if (value >= 500 && value <= 10000) {
      res = true
    }
    return res
  },
  message: 'orders.out_range_error'
})

extend('minDate', {
  params: ['target'],
  validate(value, { target }) {
    // return value > target
    // console.log('comprobando fechas', value, target, value > target)
    return value > target
  },
  message: 'La fecha de la siguiente revisión debe ser mayor que la última revisión'
});
