import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/products'

export default {
  list(page, per_page, search = '', calculator = true, type = '', category = '', orderBy = '') {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      page,
      per_page,
      search,
      calculator,
      tipo: type,
      category_id: category,
      orderBy,
    })
  },
  edit(id, product) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, product)
  },
  listControlBoxAdd(lines) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/controlboxnew`, {
      lines,
    })
  },
  create(product) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, product)
  },
  getProduct(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  delete(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  async setOrder(rows) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/reorder`, { rows: rows });
  }
}
