import addressesApi from '../../api/addresses-api'
import router from "@/router";
import i18n from '@/libs/i18n'

const state = {
  address: {},
  items: [],
  totalItems: 0,
}

const mutations = {
  setAddress(state, val) {
    state.address = val
  },
  setItems(state, val) {
    state.items = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
}

const actions = {
  edit({ commit }, { id, address }) {
    commit('app/loading', true, { root: true })
    addressesApi.edit(id, address)
      .then(
        () => {
          commit('notifications', { title: i18n.t('addresses_section.address_update_success'), variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          location.reload()
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('addresses_section.address_update_error'), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getAddress({ commit }, id) {
    commit('app/loading', true, { root: true })
    await addressesApi.getAddress(id)
      .then(
        response => {
          commit('setUser', response.data.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener la dirección.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  getListAddress({ commit }, {
    page, per_page, search = '', client = '', type = '',
  }) {
    commit('app/loading', true, { root: true })
    addressesApi.list(page, per_page, search, client, type)
      .then((response) => {
          commit('setItems', response.data.data)
          commit('setTotalItems', response.data.meta.total[1])
          commit('app/loading', false, { root: true })
        })
      .catch(() => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener el listado de direcciones.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        })
  },
  delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    addressesApi.delete(id)
      .then(
        () => {
          commit('notifications', { title: i18n.t('addresses_section.address_delete_success'), variant: 'success' }, { root: true })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('addresses_section.address_update_error'), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  create({ commit }, { address }) {
    commit('app/loading', true, { root: true })
    return new Promise((resolve, reject) => {
      addressesApi.create(address)
        .then((response) => {
            commit('notifications', { title: i18n.t('addresses_section.address_create_success'), variant: 'success' }, { root: true })
            commit('setShowNotifications', true, { root: true })
            commit('app/loading', false, { root: true })
            resolve(response)
          })
        .catch(() => {
            commit('app/loading', false, { root: true })
            commit('notifications', { title: i18n.t('addresses_section.address_create_error'), variant: 'danger' }, { root: true })
            commit('setShowNotifications', true, { root: true })
            reject()
          })
    })
  },
  createAndRecload({ commit }, { address }) {
    commit('app/loading', true, { root: true })
    addressesApi.create(address)
      .then(
        response => {
          commit('notifications', { title: i18n.t('addresses_section.address_create_success'), variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          location.reload()
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('addresses_section.address_create_error'), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
}

const getters = {
  getAddress: state => state.address,
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
