import ordersApi from '@/api/orders-api'
import router from '@/router'
import i18n from '@/libs/i18n'
import generalApi from '../../api/general-api'

const state = {
  order: {},
  listOrders: [],
  items: [],
  totalItems: 0,
  shipping: {},
  selectStatus: [],
  checkControlBox: false,
}

const mutations = {
  setlistOrders(state, val) {
    state.listOrders = val
  },
  setItems(state, val) {
    state.items = val
  },
  setOrder(state, val) {
    state.order = val
  },
  setCheckControlBox(state, val) {
    state.checkControlBox = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
  setShipping(state, val) {
    state.shipping = val
  },
  setStatusList(state, val) {
    state.selectStatus = val
  },
}

const actions = {
  async getListOrders({ commit }, {
    page, per_page, search = '', client_id = '', user_id = '', status_id, orderBy,
  }) {
    commit('app/loading', true, { root: true })
    await ordersApi.list(page, per_page, search, user_id, status_id, client_id, orderBy)
      .then(
        response => {
          commit('setItems', response.data.data)
          commit('setTotalItems', response.data.meta.total[1])
          commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('orders.no_result_search_orders'), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getOrder({ commit }, id) {
    commit('app/loading', true, { root: true })
    await ordersApi.getOrder(id)
      .then(
        response => {
          commit('setOrder', response.data.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('orders.get_order_error'), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async checkControlBox({ commit }, id) {
    await ordersApi.checkControlBox(id)
      .then(
        () => {
          commit('setCheckControlBox', true)
        },
        () => {
          commit('setCheckControlBox', false)
        },
      )
  },
  changeArchives({ commit }, { order }) {
    commit('app/loading', true, { root: true })
    ordersApi.changeArchives(order)
      .then(
        () => {
          commit('notifications', { title: i18n.t('orders.update_order_success'), variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          commit('app/loading', false, { root: true })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('orders.update_order_error'), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  create({ commit }, { order }) {
    commit('app/loading', true, { root: true })
    ordersApi.create(order)
      .then(
        response => {
          commit('notifications', { title: i18n.t('orders.create_order_success'), variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          router.push({ name: 'viewOrder', params: { id: response.data.data.id } })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('orders.create_order_error'), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  getPriceShipping({ commit }, { order = '', address = '' }) {
    commit('app/loading', true, { root: true })
    ordersApi.getPriceShipping(order, address)
      .then(
        response => {
          commit('setShipping', response.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('orders.get_shipping_cost_error'), variant: 'danger' }, { root: true })
        },
      )
  },
  finish({ commit }, { order = '', address = '', install = '' }) {
    commit('app/textloading', i18n.t('orders.generating_pdf_budget'), { root: true })
    commit('app/loading', true, { root: true })
    ordersApi.finish(order, address, install)
      .then(
        response => {
          console.log('order', response)
          commit('notifications', { title: i18n.t('orders.finish_order_success'), variant: 'success' }, { root: true })
          localStorage.removeItem('order')
          router.push({ name: 'viewOrders', params: { id: response.data.data.id } })
          // location.reload()
          commit('app/loading', false, { root: true })
          commit('app/textloading', '', { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('orders.finish_order_error'), variant: 'danger' }, { root: true })
          commit('app/textloading', '', { root: true })
        },
      )
  },
  finishRevision({ commit }, { order = '' }) {
    commit('app/textloading', i18n.t('orders.generating_pdf_budget'), { root: true })
    commit('app/loading', true, { root: true })
    ordersApi.finishRevision(order)
      .then(
        response => {
          commit('notifications', { title: i18n.t('orders.finish_order_success'), variant: 'success' }, { root: true })
          router.push({ name: 'viewOrders', params: { id: response.data.data.id } })
          commit('app/loading', false, { root: true })
          commit('app/textloading', '', { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('orders.finish_order_error'), variant: 'danger' }, { root: true })
          commit('app/textloading', '', { root: true })
        },
      )
  },
  async saveTransport({ commit }, { order = '', address = '' }) {
    commit('app/loading', true, { root: true })
    await ordersApi.saveTransport(order, address)
      .then(
        () => {
          commit('notifications', { title: i18n.t('orders.add_transport_success'), variant: 'success' }, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('orders.add_transport_error'), variant: 'danger' }, { root: true })
        },
      )
  },
  async saveFacilityTransport({ commit }, { order = '', address = '' }) {
    commit('app/loading', true, { root: true })
    await ordersApi.saveFacilityTransport(order, address)
      .then(
        () => {
          commit('notifications', { title: i18n.t('orders.add_transport_success'), variant: 'success' }, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('orders.add_transport_error'), variant: 'danger' }, { root: true })
        },
      )
  },
  delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    ordersApi.delete(id)
      .then(
        () => {
          commit('notifications', { title: i18n.t('orders.order_delete_success'), variant: 'success' }, { root: true })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('orders.order_delete_error'), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async deleteLine({ commit }, id) {
    commit('app/loading', true, { root: true })
    await ordersApi.deleteLine(id)
      .then(
        () => {
          commit('notifications', { title: i18n.t('orders.delete_line_success'), variant: 'success' }, { root: true })
          // location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('orders.delete_line_error'), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getSelectStatus({ commit }, {
    type,
  }) {
    // commit('app/loading', true, { root: true })
    await generalApi.status(
      type,
    )
      .then(
        response => {
          commit('setStatusList', response.data)
          // commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('orders.search_states_error'), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  changeStatusOrder({ commit }, {
    id, status, planned_delivery_date, created_at, url_seguimiento,
  }) {
    commit('app/loading', true, { root: true })
    ordersApi.changeStatus(id, status, planned_delivery_date, created_at, url_seguimiento)
      .then(
        () => {
          commit('notifications', { title: i18n.t('orders.status_order_updated_success'), variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          location.reload()
          // commit('app/loading', false, { root: true })
        },
        () => {
          // commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('orders.status_order_updated_error'), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async changePackaging({ commit }, params) {
    commit('app/loading', true, { root: true })
    await ordersApi.changePackaging(params.order, params.type)
      .then(
        () => {
          commit('notifications', { title: i18n.t('orders.change_packaging_success'), variant: 'success' }, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('orders.change_packaging_error'), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
}

const getters = {
  getListOrders: state => state.listOrders,
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
  getOrder: state => state.order,
  getShipping: state => state.shipping,
  getSelectStatus: state => state.selectStatus,
  getCheckControlBox: state => state.checkControlBox,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
