import stepsApi from '@/api/steps-api'
import ordersApi from '@/api/orders-api'
import i18n from '@/libs/i18n'

const state = {
  step: 1,
  longitud: '',
  altura: '',
  unidades: '',
  medidas: 1,
  order: {},
  lines: [],
  linesCuadro: [],
  linesExtras: [],
  object: {},
  cuadro: {},
  calculator: {},
}

const mutations = {
  recoverCurrentLines(state) {
    const recoveredCurrentLines = JSON.parse(localStorage.getItem('lines'))
    if (!recoveredCurrentLines) {
      state.lines = recoveredCurrentLines
    }
  },
  recoverCurrentOrder(state) {
    const recoveredCurrentOrder = JSON.parse(localStorage.getItem('order'))
    if (recoveredCurrentOrder) {
      state.order = recoveredCurrentOrder
    }
  },
  setLinesCuadro(state, val) {
    state.linesCuadro = val
  },
  setLinesExtras(state, val) {
    state.linesExtras = val
  },
  setCuadro(state, val) {
    state.cuadro = val
  },
  setCalculator(state, val) {
    state.calculator = val
  },
  setStep(state, val) {
    state.step = val
  },
  setMedidas(state, val) {
    state.medidas = val
  },
  setLongitud(state, val) {
    state.longitud = val
  },
  setAltura(state, val) {
    state.altura = val
  },
  setUnidades(state, val) {
    state.unidades = val
  },
  setObject(state, val) {
    state.object = val
  },
  async setOrder(state, val) {
    state.order = val
    if (localStorage.getItem('order')) localStorage.removeItem('order')
    localStorage.setItem('order', JSON.stringify(state.order))
  },
  setLines(state, val) {
    state.lines = val
    localStorage.setItem('lines', JSON.stringify(state.lines))
  },
}

const actions = {
  checkOrder() {
    if (!Object.keys(state.order).length) {
      return false
    }
    return true
  },
  async checkOrderOrCreate({ commit }) {
    if (!Object.keys(state.order).length) {
      const recoveredUser = JSON.parse(localStorage.getItem('userData'))
      let id = 1
      if (recoveredUser.roles[0].name !== 'super_admin') {
        id = recoveredUser.clients[0].id
      }
      const order = {
        client_id: id,
      }
      await ordersApi.create(order)
        .then(
          response => {
            commit('setOrder', response.data.data)
            commit('app/loading', false, { root: true })
          },
          () => {
            commit('app/loading', false, { root: true })
            commit('notifications', {
              title: i18n.t('orders.create_budget_error'),
              variant: 'danger',
            }, { root: true })
            commit('setShowNotifications', true, { root: true })
          },
        )
    }
  },
  async getOrder({ commit, dispatch }, order_id = null) {
    if (order_id === null) order_id = state.order.id
    await ordersApi.getOrder(order_id)
      .then(
        response => {
          commit('setOrder', response.data.data)
          commit('setShowNotifications', true, { root: true })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('orders.get_order_error'), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async addLine({ commit, dispatch }, {
    amount, quantity, product_id, order_id, atributos,
  }) {
    await commit('app/loading', true, { root: true })
    await ordersApi.createLine({
      amount,
      quantity,
      product_id,
      order_id,
      atributos,
    })
      .then(
        () => {
          commit('notifications', { title: i18n.t('orders.add_line_success'), variant: 'success' }, { root: true })
          ordersApi.getOrder(state.order.id)
            .then(
              response => {
                commit('setUnidades', '')
                commit('setCuadro', {})
                commit('setLinesCuadro', [])
                commit('setObject', {})
                commit('setAltura', '')
                commit('setLongitud', '')
                commit('setMedidas', '')
                commit('setOrder', response.data.data)
                commit('setShowNotifications', true, { root: true })
                commit('setStep', 4)
                commit('app/loading', false, { root: true })
              },
              () => {
                commit('app/loading', false, { root: true })
                commit('notifications', { title: i18n.t('orders.get_order_error'), variant: 'danger' }, { root: true })
                commit('setShowNotifications', true, { root: true })
              },
            )
        },
        () => {
          commit('app/loading', false, { root: true })
          let title = ''
          if (this.state.steps.step) title = i18n.t('orders.select_panel_to_continue')
          else title = i18n.t('orders.add_line_error')
          commit('notifications', { title, variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  calculator({ state, commit }, { calculator }) {
    commit('app/loading', true, { root: true })
    stepsApi.getCalculator(calculator)
      .then(
        response => {
          commit('setCalculator', response.data)
          commit('setStep', state.step === 4.2 ? 4.3 : 3)
          commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          if (error.response.data.error && error.response.data.error === 'l1 supera 12000') {
            commit('notifications', { title: `${i18n.t('orders.size_max_allowed')} (L1 > 12000 ; H1 > 8000)`, variant: 'danger' }, { root: true })
          } else if (error.response.data.error && error.response.data.error === 'h1 supera 8000') {
            commit('notifications', { title: `${i18n.t('orders.size_max_allowed')} (L1 > 12000 ; H1 > 8000)`, variant: 'danger' }, { root: true })
          } else {
            commit('notifications', { title: error.response.data.error, variant: 'danger' }, { root: true })
          }
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  uploadCalculator({ commit }, { calculator }) {
    commit('app/loading', true, { root: true })
    stepsApi.uploadCalculator(calculator)
      .then(
        () => {
          commit('notifications', { title: i18n.t('orders.upload_calculator_success'), variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          commit('app/loading', false, { root: true })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('orders.upload_calculator_error'), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  send({ commit }, { form }) {
    commit('app/loading', true, { root: true })
    stepsApi.send(form)
      .then(
        response => {
          commit('notifications', { title: i18n.t('orders.form_send_success'), variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          if (state.order && state.order.user) {
            commit('setStep', 4)
          } else {
            commit('setStep', 1)
          }
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('orders.form_send_error'), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
}

const getters = {
  getStep: state => state.step,
  getOrder: state => state.order,
  getLongitud: state => state.longitud,
  getAltura: state => state.altura,
  getUnidades: state => state.unidades,
  getMedidas: state => state.medidas,
  getLines: state => state.lines,
  getObject: state => state.object,
  getCalculator: state => state.calculator,
  getLinesCuadro: state => state.linesCuadro,
  getLinesExtras: state => state.linesExtras,
  getCuadro: state => state.cuadro,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
