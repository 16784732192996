import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/provinces'

export default {
  list(page, per_page, search = '', orderBy = '') {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      page,
      per_page,
      search,
      orderBy,
    })
  },
  edit(id, province) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, province)
  },
  getProvince(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  uploadPrices(prices) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/import`, prices)
  },
}
