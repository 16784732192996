import assetsApi from '@/api/assets-api'
import router from '@/router'

const state = {
  asset: {},
  listAssets: [],
  selectAssets: [],
  items: [],
  totalItems: 0,
  controlboxadd: 0,
  ini: [],
  file: ''
}

const mutations = {
  setControlBoxAdd(state, val) {
    state.controlboxadd = val
  },
  setlistAssets(state, val) {
    state.listAssets = val
  },
  setSelectAssets(state, val) {
    state.selectAssets = val
  },
  setItems(state, val) {
    state.items = val
  },
  setAsset(state, val) {
    state.asset = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
  setDataIni(state, val) {
    state.ini = val
  },
  setDataFile(state, val) {
    state.file = val
  }
}

const actions = {
  async selectAssets({ commit }, {
    client, project, page, per_page, status_id, category_id, maintenance_id, location_id, search = ''
  }) {
    console.log('location_id', location_id)
    await assetsApi.list(client, project, page, per_page, status_id, category_id, maintenance_id, location_id, search)
      .then(
        response => {
          commit('setSelectAssets', response.data.data)
        },
        () => {
          commit('notifications', { title: 'Error en la búsqueda de assets.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async searchAssets({ commit }, {
    search = '',
  }) {
    commit('app/loading', true, { root: true })
    await assetsApi.list(1, 9999, search)
      .then(
        response => {
          commit('setlistAssets', response.data.data)
          commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error en la búsqueda de assets.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getListAssets({ commit }, {
    id_client, project_id, maintenance_id, page, per_page, status_id, category_id, location_id, search = ''
  }) {
    commit('app/loading', true, { root: true })
    await assetsApi.list(id_client, project_id, maintenance_id, page, per_page, status_id, category_id, location_id, search)
      .then(
        response => {
          commit('setItems', response.data.data)
          commit('setTotalItems', response.data.meta.total[1])
          commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error en la búsqueda de equipos.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getListControlBoxAdd({ commit }, {
    lines = [],
  }) {
    commit('app/loading', true, { root: true })
    await assetsApi.listControlBoxAdd(lines)
      .then(
        response => {
          commit('setControlBoxAdd', response.data)
          commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error en la búsqueda de cuadros de control.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getAsset({ commit }, id) {
    commit('app/loading', true, { root: true })
    await assetsApi.getAsset(id)
      .then(
        response => {
          commit('setAsset', response.data.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener el equipo.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  edit({ commit }, { id, asset }) {
    commit('app/loading', true, { root: true })
    assetsApi.edit(id, asset)
      .then(
        () => {
          commit('notifications', { title: 'Equipo actualizado con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          commit('app/loading', false, { root: true })
          router.push({ name: 'assets' })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al actualizar el equipo.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  create({ commit }, { asset }) {
    commit('app/loading', true, { root: true })
    assetsApi.create(asset)
      .then(
        response => {
          // console.log('asset', asset, response.data.data.project.id)
          commit('notifications', { title: 'Equipo creado con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          router.push({ name: 'viewProject', params: { id: response.data.data.project.id } })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al crear equipo.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  delete({ commit }, asset) {
    commit('app/loading', true, { root: true })
    assetsApi.delete(asset.id)
      .then(
        () => {
          commit('notifications', { title: 'Equipo eliminado con éxito.', variant: 'success' }, { root: true })
          router.push({ name: 'projects' })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al eliminar el equipo.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  unassign({ commit }, asset) {
    commit('app/loading', true, { root: true })
    assetsApi.unassign(asset)
      .then(
        () => {
          commit('notifications', { title: 'Equipo eliminado del proyecto con éxito.', variant: 'success' }, { root: true })
          location.reload()
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al eliminar el equipo del proyecto. Este equipo esta asignado en algún mantenimiento', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  getIni({ commit }, id) {
    //add loading
    assetsApi.getIni(id)
      .then(
        response => {
          commit('setDataIni', response.data.data)
        },
        () => {
          commit('notifications', { title: 'Revisando ini...', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })

          commit('app/loading', false, { root: true })
          router.push({ name: 'assets' })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al actualizar el equipo.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async setIni({ commit }, { ini }) {
    commit('app/loading', true, { root: true })
    assetsApi.setIni(ini)
      .then(
        response => {
          // console.log('response ok', response.data)

          let blob = new Blob([response.data], { type: "text/plain" })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'fichero.ini'
          link.click()

          router.go(0)

        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al crear el fichero.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  }
}

const getters = {
  getSelectAssets: state => { return state.selectAssets },
  getListAssets: state => { return state.listAssets },
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
  getAsset: state => state.asset,
  getControlBoxAdd: state => state.controlboxadd,
  getAssets: state => state.items,
  getDataIni: state => { return state.ini },
  getDataFile: state => { return state.file },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
