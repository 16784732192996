import provincesApi from '@/api/provinces-api'
import router from '@/router'
import stepsApi from '@/api/steps-api'

const state = {
  province: {},
  selectProvinces: [],
  items: [],
  totalItems: 0,
}

const mutations = {
  setSelectProvinces(state, val) {
    state.selectProvinces = val
  },
  setItems(state, val) {
    state.items = val
  },
  setProvince(state, val) {
    state.province = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
}

const actions = {
  async selectProvinces({ commit }, {
    page, per_page, search = '', orderBy,
  }) {
    try {
      const response = await provincesApi.list(page, per_page, search, orderBy)
      commit('setSelectProvinces', response.data.data)
    } catch (error) {
      console.error(error)
      commit('notifications', { title: 'Error en la búsqueda de provincias.', variant: 'danger' }, { root: true })
      commit('setShowNotifications', true, { root: true })
    }
  },
  async getListProvinces({ commit }, {
    page, per_page, search = '', orderBy
  }) {
    commit('app/loading', true, { root: true })
    await provincesApi.list(page, per_page, search, orderBy)
      .then(
        response => {
          commit('setItems', response.data.data)
          commit('setTotalItems', response.data.meta.total[1])
          commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error en la búsqueda de provincias.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getProvince({ commit }, id) {
    commit('app/loading', true, { root: true })
    await provincesApi.getProvince(id)
      .then(
        response => {
          commit('setProvince', response.data.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener la provincia.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  edit({ commit }, { id, province }) {
    commit('app/loading', true, { root: true })
    provincesApi.edit(id, province)
      .then(
        () => {
          commit('notifications', { title: 'Provincia actualizada con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          commit('app/loading', false, { root: true })
          router.push({ name: 'provinces' })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al actualizar la provincia.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  uploadPrices({ commit }, { prices }) {
    commit('app/loading', true, { root: true })
    provincesApi.uploadPrices(prices)
      .then(
        () => {
          commit('notifications', { title: 'Precios actualizados con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          commit('app/loading', false, { root: true })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al actualizar.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
}

const getters = {
  getSelectProvinces: state => { return state.selectProvinces },
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
  getProvince: state => state.province,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
