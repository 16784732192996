import projectsApi from '@/api/projects-api'
import generalApi from '../../api/general-api'

import router from '@/router'

const state = {
  project: {},
  listProjects: [],
  selectProjects: [],
  items: [],
  totalItems: 0,
  controlboxadd: 0,
  selectStatus: []
}

const mutations = {
  setControlBoxAdd(state, val) {
    state.controlboxadd = val
  },
  setlistProjects(state, val) {
    state.listProjects = val
  },
  setSelectProjects(state, val) {
    state.selectProjects = val
  },
  setItems(state, val) {
    state.items = val
  },
  setProject(state, val) {
    state.project = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
  setStatusList(state, val) {
    state.selectStatus = val
  }
}

const actions = {
  async selectProjects({ commit }, {
    client = '', page, per_page, search, orderBy, status, province_id
  }) {
    await projectsApi.getList(
      client,
      page,
      per_page,
      search,
      orderBy,
      status)
      .then(
        response => {
          commit('setSelectProjects', response.data.data)
        },
        () => {
          commit('notifications', { title: 'Error en la búsqueda de obras.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },

  async searchProjects({ commit }, {
    search = '',
  }) {
    commit('app/loading', true, { root: true })
    await projectsApi.list(1, 9999, search)
      .then(
        response => {
          commit('setlistProjects', response.data.data)
          commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error en la búsqueda de obras.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },

  async getListProjects({ commit }, {
    client, page, per_page, search, orderBy, status, province_id, incidence
  }) {
    commit('app/loading', true, { root: true })
    await projectsApi.list(
      client, page, per_page, search, orderBy, status, province_id, incidence)
      .then(
        response => {
          commit('setItems', response.data.data)
          commit('setTotalItems', response.data.meta.total[1])
          commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error en la búsqueda de obras.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },

  async getListControlBoxAdd({ commit }, {
    lines = [],
  }) {
    commit('app/loading', true, { root: true })
    await projectsApi.listControlBoxAdd(lines)
      .then(
        response => {
          commit('setControlBoxAdd', response.data)
          commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error en la búsqueda de cuadros de control.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },

  async getProject({ commit }, id) {
    commit('app/loading', true, { root: true })
    await projectsApi.getProject(id)
      .then(
        response => {
          commit('setProject', response.data.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener la obra.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },

  async getSelectStatus({ commit }, {
    type
  }) {
    commit('app/loading', true, { root: true })
    await generalApi.status(
      type)
      .then(
        response => {
          commit('setStatusList', response.data.data)
          commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error en la búsqueda de estados.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  edit({ commit }, { id, project }) {
    commit('app/loading', true, { root: true })
    projectsApi.edit(id, project)
      .then(
        () => {
          commit('notifications', { title: 'Obra actualizado con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          commit('app/loading', false, { root: true })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al actualizar la obra.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  create({ commit }, { project }) {
    commit('app/loading', true, { root: true })
    projectsApi.create(project)
      .then(
        response => {
          commit('notifications', { title: 'Obra creado con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          router.push({ name: 'projects' })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al crear la obra.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },

  delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    console.log('proyecto a eliminar', id)
    projectsApi.delete(id)
      .then(
        () => {
          commit('notifications', { title: 'Obra eliminado con éxito.', variant: 'success' }, { root: true })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al eliminar la obra.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },

  async deleteDoc({ commit }, { id_project, id_documento }) {
    commit('app/loading', true, { root: true })
    projectsApi.deleteDoc(id_project, id_documento)
      .then(
        () => {
          commit('notifications', { title: 'Documento eliminado.', variant: 'success' }, { root: true })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al eliminar el documentoƒ.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getSelectStatus({ commit }, {
    type
  }) {
    // commit('app/loading', true, { root: true })
    await generalApi.status(
      type)
      .then(
        response => {
          commit('setStatusList', response.data)
          // commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error en la búsqueda de estados.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  }

}

const getters = {
  getSelectProjects: state => { return state.selectProjects },
  getListProjects: state => { return state.listProjects },
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
  getProject: state => state.project,
  getControlBoxAdd: state => state.controlboxadd,
  getSelectStatus: state => state.statuslist

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
