export const config = {
  defaultLocale: 'es',
  locales: ['es', 'en', 'fr', 'it'],
  localesFlatPicker: {
    es: 'es',
    en: 'en',
    fr: 'fr',
    it: 'it',
  },
  statusProyectIcon: {
    active: {
      icon: 'CheckCircleIcon',
      show: 'light-success',
    },
    inactive: {
      icon: 'XCircleIcon',
      show: 'light-danger',
    },
  },
  statusOrderVariants: {
    pending: 'light-info',
    budget: 'light-secondary',
    budget_admin: 'light-secondary',
    ready: '<iconPreparacion class="icon-status"/>',
    send: 'light-danger',
    cast: 'light-success',
    delivered: 'light-success',
    canceled: 'light-success',
  },
  // statusOrderText: {
  //   pending: i18n.t('status.orders.pending'),
  //   budget: i18n.t('status.orders.budget'),
  //   budget_admin: i18n.t('status.orders.budget_admin'),
  //   ready: i18n.t('status.orders.ready'),
  //   send: i18n.t('status.orders.send'),
  //   cast: i18n.t('status.orders.cast'),
  //   delivered: i18n.t('status.orders.delivered'),
  //   canceled: i18n.t('status.orders.canceled'),
  // },
  statusAssetIcons: {
    inactive: 'XCircleIcon',
    active: 'CheckCircleIcon',
    incidence: 'AlertCircleIcon',
  },
  statusAssetVariants: {
    inactive: 'light-danger',
    active: 'light-success',
    incidence: 'light-warning',
  },
  nameApp: 'Tecnitex',
  webApiBase: process.env.VUE_APP_ROOT_API,
  placeholder: process.env.VUE_APP_IMAGE_PLACEHOLDER,
  placeholderDocuments: process.env.VUE_APP_IMAGE_PLACEHOLDER_DOCUMENTS,
  frequencyText: {
    // mensual, trimestral, semestral, anual
    // daily: 'Diario',
    // weekly: 'Semanal',
    monthly: 'Mensual',
    quarterly: 'Trimestral',
    biannual: 'Semestral',
    annual: 'Anual',
  },
  frequencySelect: [
    {
      name: 'Mensual',
      value: 'monthly',
    },
    {
      name: 'Trimestral',
      value: 'quarterly',
    },
    {
      name: 'Semestral',
      value: 'biannual',
    },
    {
      name: 'Anual',
      value: 'annual',
    },
  ],
  tipePriceSelect: [
    {
      name: 'Precio fijo',
      value: 'fijo',
    },
    {
      name: 'Precio por mm de L1',
      value: 'mml',
    },
    {
      name: 'Precio por mm de H2',
      value: 'mmh',
    },
  ],
  statusMaintenanceVariants: {
    pending: 'light-warning',
    inbox: 'light-secondary',
    in_progress: 'light-primary',
    complete: 'light-success',
    unfavorable: 'secondary',
  },
  statusIncidenceTexts: {
    0: 'Abierta',
    1: 'Cerrada',
    false: 'Abierta',
    true: 'Cerrada',
  },
  statusIncidenceVariants: {
    0: 'light-danger',
    1: 'light-primary',
  },
}
