import alertsApi from '@/api/alerts-api'
import router from '@/router'
import store from '@/store'

const state = {
  alert: {},
  alertsList: [],
  selectAlertTypes: [],
  items: [],
  totalItems: 0,
}

const mutations = {

  setItems(state, val) {
    state.items = val
  },
  setSelectAlertTypes(state, val) {
    state.selectAlertTypes = val
  },
  setIncidence(state, val) {
    state.incidence = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
}

const actions = {

  async getAlerts({ commit }, {
    page, per_page, search = '',type_id, client_id, 
  }) {
    commit('app/loading', true, { root: true })
    await alertsApi.list(page, per_page, search, type_id, client_id)
      .then(
        response => {
          commit('setItems', response.data.data)
          commit('setTotalItems', response.data.meta.total[1])
          commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error en la búsqueda de alertas.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getSelectAlertTypes({ commit },) {
    commit('app/loading', true, { root: true })
    await alertsApi.tipos()
      .then(
        response => {
          commit('setSelectAlertTypes', response.data)
          commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error en tipos de alertas.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  // async getIncidence({ commit }, id) {
  //   commit('app/loading', true, { root: true })
  //   await incidencesApi.get(id)
  //     .then(
  //       response => {
  //         commit('setIncidence', response.data.data)
  //         console.log('incidencia', response.data.data)
  //         commit('app/loading', false, { root: true })
  //       },
  //       () => {
  //         commit('app/loading', false, { root: true })
  //         commit('notifications', { title: 'Error al obtener la incidencia', variant: 'danger' }, { root: true })
  //         commit('setShowNotifications', true, { root: true })
  //       },
  //     )
  // },
  // edit({ commit }, { id, incidence }) {
  //   commit('app/loading', true, { root: true })
  //   incidencesApi.edit(id, incidence)
  //     .then(
  //       response => {
  //         commit('notifications', { title: 'Incidencia actualizado con éxito.', variant: 'success' }, { root: true })
  //         commit('setShowNotifications', true, { root: true })
  //         commit('app/loading', false, { root: true })
  //         if (store.getters['auth/getRole'] === 'user' || store.getters['auth/getRole'] === 'admin') {
  //           router.push({ name: 'viewMaintenance', params: { id: response.data.data.id } })
  //         } else {
  //           router.push({ name: 'maintenances' })
  //         }
  //       },
  //       () => {
  //         commit('app/loading', false, { root: true })
  //         commit('notifications', { title: 'Error al actualizar el maintenancee.', variant: 'danger' }, { root: true })
  //         commit('setShowNotifications', true, { root: true })
  //       },
  //     )
  // },
  // create({ commit }, { incidence }) {
  //   commit('app/loading', true, { root: true })
  //   incidencesApi.create(incidence)
  //     .then(
  //       response => {
  //         commit('notifications', { title: 'Incidencia creada con éxito.', variant: 'success' }, { root: true })
  //         commit('setShowNotifications', true, { root: true })
  //         router.push({ name: 'incidence' })
  //         commit('app/loading', false, { root: true })
  //       },
  //       () => {
  //         commit('app/loading', false, { root: true })
  //         commit('notifications', { title: 'Error al crear maintenancee.', variant: 'danger' }, { root: true })
  //         commit('setShowNotifications', true, { root: true })
  //       },
  //     )
  // },
  // delete({ commit }, id) {
  //   commit('app/loading', true, { root: true })
  //   incidencesApi.delete(id)
  //     .then(
  //       () => {
  //         commit('notifications', { title: 'Maintenancee eliminado con éxito.', variant: 'success' }, { root: true })
  //         location.reload()
  //       },
  //       () => {
  //         commit('app/loading', false, { root: true })
  //         commit('notifications', { title: 'Error al eliminar el maintenancee.', variant: 'danger' }, { root: true })
  //         commit('setShowNotifications', true, { root: true })
  //       },
  //     )
  // },
}

const getters = {
  getSelectAlertTypes: state => { return state.selectAlertTypes },
  selectAlertTypes: state => state.selectAlertTypes,

  getAlerts: state => { return state.items },

  getItems: state => state.items,
  getTotalItems: state => state.totalItems,

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
