import axios from 'axios'
import store from "@/store";

axios.interceptors.request.use(config => {
  const token = JSON.parse(localStorage.getItem('token'))
  if (token && token.token) {
    config.headers.Authorization = `${token.token_type} ${token.token}`
  }
  config.headers['X-localization'] = store.getters['languages/getCurrentLanguage']

  return config
}, err => Promise.reject(err))
