import paymentsApi from '@/api/payments-api'
import router from '@/router'

const state = {
  payment: {},
  listPayments: [],
  selectPayments: [],
  items: [],
  totalItems: 0,
}

const mutations = {
  setlistPayments(state, val) {
    state.listPayments = val
  },
  setSelectPayments(state, val) {
    state.selectPayments = val
  },
  setItems(state, val) {
    state.items = val
  },
  setPayment(state, val) {
    state.payment = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
}

const actions = {
  async selectPayments({ commit }, {
      page, per_page, search = '', orderBy
  }) {
    await paymentsApi.list(page, per_page, search, orderBy)
      .then(
        response => {
          commit('setSelectPayments', response.data.data)
        },
        () => {
          commit('notifications', { title: 'Error en la búsqueda de formas de pago.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async searchPayments({ commit }, {
    search = '',
  }) {
    commit('app/loading', true, { root: true })
    await paymentsApi.list(1, 9999, search)
      .then(
        response => {
          commit('setlistPayments', response.data.data)
          commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error en la búsqueda de formas de pago.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getListPayments({ commit }, {
    page, per_page, search = '', orderBy
}) {
    commit('app/loading', true, { root: true })
    await paymentsApi.list(page, per_page, search, orderBy)
      .then(
        response => {
          commit('setItems', response.data.data)
          commit('setTotalItems', response.data.meta.total[1])
          commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error en la búsqueda de formas de pago.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getPayment({ commit }, id) {
    commit('app/loading', true, { root: true })
    await paymentsApi.getPayment(id)
      .then(
        response => {
          commit('setPayment', response.data.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener la forma de pago.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  edit({ commit }, { id, payment }) {
    commit('app/loading', true, { root: true })
    paymentsApi.edit(id, payment)
      .then(
        () => {
          commit('notifications', { title: 'Forma de pago actualizada con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          commit('app/loading', false, { root: true })
          router.push({ name: 'payments' })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al actualizar la forma de pago.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  create({ commit }, { payment }) {
    commit('app/loading', true, { root: true })
    paymentsApi.create(payment)
      .then(
        response => {
          commit('notifications', { title: 'Forma de pago creada con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          router.push({ name: 'payments' })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al crear forma de pago.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    paymentsApi.delete(id)
      .then(
        () => {
          commit('notifications', { title: 'Forma de pago eliminada con éxito.', variant: 'success' }, { root: true })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al eliminar la forma de pago.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
}

const getters = {
  getSelectPayments: state => { return state.selectPayments },
  getListPayments: state => { return state.listPayments },
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
  getPayment: state => state.payment,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
