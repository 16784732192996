import router from '@/router'
import categoriesApi from '../../api/categories-api'
import i18n from '@/libs/i18n'

const state = {
  category: {},
  items: [],
  selectCategories: [],
  selectCategoriesParent: [],
  totalItems: 0,
  totalItemsCreateProject: 0,
}

const mutations = {
  setCategory(state, val) {
    state.category = val
  },
  setItems(state, val) {
    state.items = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
  setSelectCategories(state, val) {
    state.selectCategories = val
  },
  setSelectCategoriesParent(state, val) {
    state.selectCategoriesParent = val
  },
}

const actions = {
  async getCategory({ commit }, id) {
    commit('app/loading', true, { root: true })
    await categoriesApi.getCategory(id)
      .then(
        response => {
          commit('setCategory', response.data.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('products.get_project_error'), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  getListCategories({ commit }, {
    page, per_page, search = '', parent = '',
  }) {
    commit('app/loading', true, { root: true })
    categoriesApi.getList(page, per_page, search, parent)
      .then(
        response => {
          commit('setItems', response.data.data)
          commit('setTotalItems', response.data.meta.total[1])
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: i18n.t('products.get_list_categories_error'), variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getSelectCategories({ commit }, { parent = '', only_children = false }) {
    try {
      const response = await categoriesApi.getList(null, null, '', parent, only_children)
      if (parent === 0) {
        commit('setSelectCategoriesParent', response.data.data)
      } else {
        commit('setSelectCategories', response.data.data)
      }
    } catch (error) {
      console.error(error)
      commit('notifications', { title: i18n.t('products.get_list_categories_error'), variant: 'danger' }, { root: true })
      commit('setShowNotifications', true, { root: true })
    }
  },
  edit({ commit }, { id, category }) {
    commit('app/loading', true, { root: true })
    categoriesApi.edit(id, category)
      .then(
        () => {
          commit('notifications', { title: 'Categoría actualizada con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          commit('app/loading', false, { root: true })
          router.push({ name: 'categories' })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al actualizar categoría.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  create({ commit }, { category }) {
    commit('app/loading', true, { root: true })
    categoriesApi.create(category)
      .then(
        response => {
          commit('notifications', { title: 'Categoría creada con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          router.push({ name: 'categories' })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al crear categoría.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    categoriesApi.delete(id)
      .then(
        () => {
          commit('notifications', { title: 'Categoría eliminada con éxito.', variant: 'success' }, { root: true })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al eliminar la categoría.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
}

const getters = {
  getCategory: state => state.category,
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
  getSelectCategories: state => state.selectCategories,
  getSelectCategoriesParent: state => state.selectCategoriesParent,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
