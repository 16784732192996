import locationsApi from '@/api/locations-api'
import router from '@/router'

const state = {
  location: {},
  listLocations: [],
  selectLocations: [],
  items: [],
  totalItems: 0,
  controlboxadd: 0,
}

const mutations = {
  setControlBoxAdd(state, val) {
    state.controlboxadd = val
  },
  setlistLocations(state, val) {
    state.listLocations = val
  },
  setSelectLocations(state, val) {
    state.selectLocations = val
  },
  setItems(state, val) {
    state.items = val
  },
  setLocation(state, val) {
    state.location = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
}

const actions = {
  async selectLocations({ commit }, {
    client, project, page, per_page, search = '',
  }) {
    await locationsApi.list(client, project, page, per_page, search)
      .then(
        response => {
          commit('setSelectLocations', response.data.data)
        },
        () => {
          commit('notifications', { title: 'Error en la búsqueda de locations.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async searchLocations({ commit }, {
    search = '',
  }) {
    commit('app/loading', true, { root: true })
    await locationsApi.list(1, 9999, search)
      .then(
        response => {
          commit('setlistLocations', response.data.data)
          commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error en la búsqueda de locations.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getListLocations({ commit }, {
    client, project, page, per_page, search = '',
  }) {
    commit('app/loading', true, { root: true })
    await locationsApi.list(client, project, page, per_page, search)
      .then(
        response => {
          commit('setItems', response.data.data)
          commit('setTotalItems', response.data.meta.total[1])
          commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error en la búsqueda de zonas.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getListControlBoxAdd({ commit }, {
    lines = [],
  }) {
    commit('app/loading', true, { root: true })
    await locationsApi.listControlBoxAdd(lines)
      .then(
        response => {
          commit('setControlBoxAdd', response.data)
          commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error en la búsqueda de cuadros de control.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getLocation({ commit }, id) {
    commit('app/loading', true, { root: true })
    await locationsApi.getLocation(id)
      .then(
        response => {
          commit('setLocation', response.data.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener el zona.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  edit({ commit }, { id, location }) {
    commit('app/loading', true, { root: true })
    locationsApi.edit(id, location)
      .then(
        () => {
          commit('notifications', { title: 'Zona actualizado con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          commit('app/loading', false, { root: true })
          // router.push({ name: 'locations' })
          router.go(-1)
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al actualizar el zona.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  create({ commit }, { location }) {
    commit('app/loading', true, { root: true })
    locationsApi.create(location)
      .then(
        response => {
          // console.log('location', location, response.data.data.project.id)
          commit('notifications', { title: 'Zona creado con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          // router.push({ name: 'viewProject', params: { id: response.data.data.project.id } })
          router.go(-1)
          commit('app/loading', false, { root: true })
        },
        () => {
          console.log('location', location)
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al crear zona.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  delete({ commit }, location) {
    commit('app/loading', true, { root: true })
    locationsApi.delete(location.id)
      .then(
        () => {
          commit('notifications', { title: 'Zona eliminado con éxito.', variant: 'success' }, { root: true })
          // router.push({ name: 'projects' })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al eliminar el zona.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
}

const getters = {
  getSelectLocations: state => { return state.selectLocations },
  getListLocations: state => { return state.listLocations },
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
  getLocation: state => state.location,
  getControlBoxAdd: state => state.controlboxadd,
  getZones: state => state.items,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
