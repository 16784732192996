import productsApi from '@/api/products-api'
import router from '@/router'

const state = {
  product: {},
  listProducts: [],
  selectProducts: [],
  items: [],
  totalItems: 0,
  controlboxadd: 0,
}

const mutations = {
  setControlBoxAdd(state, val) {
    state.controlboxadd = val
  },
  setlistProducts(state, val) {
    state.listProducts = val
  },
  setSelectProducts(state, val) {
    state.selectProducts = val
  },
  setItems(state, val) {
    state.items = val
  },
  setProduct(state, val) {
    state.product = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
}

const actions = {
  async selectProducts({ commit }, {
    page, per_page, search = '', calculator = true, type = '', category = '', orderBy,
  }) {
    await productsApi.list(page, per_page, search, calculator, type, category, orderBy)
      .then(
        response => {
          commit('setSelectProducts', response.data.data)
        },
        () => {
          commit('notifications', { title: 'Error en la búsqueda de productos.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async searchProducts({ commit }, {
    search = '',
  }) {
    commit('app/loading', true, { root: true })
    await productsApi.list(1, 9999, search)
      .then(
        response => {
          commit('setlistProducts', response.data.data)
          commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error en la búsqueda de productos.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getListProducts({ commit, rootGetters }, {
    page, per_page, search = '', calculator = false, type = '', category = '', orderBy,
  }) {
    commit('app/loading', true, { root: true })
    await productsApi.list(page, per_page, search, calculator, type, category, orderBy)
      .then(
        response => {
          commit('setItems', response.data.data)
          commit('setTotalItems', response.data.meta.total[1])
          commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error en la búsqueda de productos.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getListControlBoxAdd({ commit }, {
    lines = [],
  }) {
    commit('app/loading', true, { root: true })
    await productsApi.listControlBoxAdd(lines)
      .then(
        response => {
          commit('setControlBoxAdd', response.data)
          commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error en la búsqueda de cuadros de control.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getProduct({ commit }, id) {
    commit('app/loading', true, { root: true })
    try {
      const response = await productsApi.getProduct(id)
      commit('setProduct', response.data.data)
    } catch (error) {
      commit('notifications', { title: 'Error al obtener el producto.', variant: 'danger' }, { root: true })
      commit('setShowNotifications', true, { root: true })
    }

    commit('app/loading', false, { root: true })
  },
  edit({ commit }, { id, product }) {
    commit('app/loading', true, { root: true })
    productsApi.edit(id, product)
      .then(
        response => {
          commit('notifications', { title: 'Producto actualizado con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          commit('app/loading', false, { root: true })
          router.push({ name: 'viewProduct', params: { id: response.data.data.id } })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al actualizar el producto.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  create({ commit }, { product }) {
    commit('app/loading', true, { root: true })
    productsApi.create(product)
      .then(
        response => {
          commit('notifications', { title: 'Producto creado con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          router.push({ name: 'viewProduct', params: { id: response.data.data.id } })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al crear producto.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    productsApi.delete(id)
      .then(
        () => {
          commit('notifications', { title: 'Producto eliminado con éxito.', variant: 'success' }, { root: true })
          // router.push({ name: 'products' })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'No se ha podido eliminar este producto', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async setOrder({ commit }, rows) {
    // commit('app/loading', true, { root: true })

    try {
      const response = await productsApi.setOrder(rows)
      commit('notifications', { title: 'Producto ordenado con éxito.', variant: 'success' }, { root: true })

    } catch (error) {
      commit('notifications', { title: 'Error al ordenar producto.', variant: 'danger' }, { root: true })

    }
    commit('setShowNotifications', true, { root: true })
    // commit('app/loading', false, { root: true })

  }
}

const getters = {
  getSelectProductos: state => state.selectProducts,
  getListProducts: state => state.listProducts,
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
  getProduct: state => state.product,
  getControlBoxAdd: state => state.controlboxadd,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
