import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/projects'

export default {
  list(client, page, per_page, search, orderBy, status_id, province_id, incidence) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      id_client: client,
      page,
      per_page,
      search,
      orderBy,
      status_id,
      province_id,
      incidence
    })
  },
  edit(id, project) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, project)
  },
  create(project) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, project)
  },
  getProject(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  delete(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  getList(client = '', page, per_page, search, orderBy, status = '', province_id, date_ini = '', date_fin = '') {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      id_client: client,
      page,
      per_page,
      search,
      orderBy,
      status,
      province_id
    })
  },
  deleteDoc(id_project, id_documento) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/delete_doc/${id_project}/${id_documento}`)
  }
}
