import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/assets'

export default {

  list(id_client = '', project_id, maintenance_id, page, per_page, status_id, category_id, location_id, search) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      id_client,
      project_id,
      maintenance_id,
      page,
      per_page,
      status_id,
      category_id,
      location_id,
      search,
    })
  },
  edit(id, asset) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, asset)
  },
  create(asset) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, asset)
  },
  getAsset(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  delete(asset) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${asset}`)
  },
  getIni(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/ini/${id}`)
  },
  setIni(ini) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/ini/generate`, ini)
  },
  unassign(asset) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/unassign_project/${asset}`)
  },
}
