import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/maintenances'

export default {

  list(page, per_page, search, id_client, status_id, project_id, asset_id, date_ini, date_fin) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      page,
      per_page,
      search,
      id_client,
      project_id,
      status_id,
      asset_id,
      date_ini,
      date_fin,
      orderBy: { "column": "id", "type": "desc" }

    })

  },
  edit(id, maintenance) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, maintenance)
  },
  create(maintenance) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, maintenance)
  },
  delete(maintenance) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${maintenance}`)
  },
  getMaintenance(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  changeApto(id, maintenance) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/changeapto/${id}`, maintenance)
  },
  review(id, maintenance) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/review/${id}`, maintenance)
  },
  init(id) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/init/${id}`)
  },
  close(id, params) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/close/${id}`, params)
  },

}
