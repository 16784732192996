import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/countries'

export default {
  list(page, per_page, search = '', orderBy = '') {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, { 
      page,
      per_page,
      search,
      orderBy
    })
  }
}