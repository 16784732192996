import incidencesApi from '@/api/incidences-api'
import router from '@/router'
import store from '@/store'

const state = {
  incidence: {},
  currentMaintenance: null,
  showModalMaintenances: false,
  listMaintenances: [],
  selectMaintenances: [],
  items: [],
  totalItems: 0,
}

const mutations = {
  setItems(state, val) {
    state.items = val
  },
  setIncidence(state, val) {
    state.incidence = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
}

const actions = {
  async selectMaintenances({ commit }) {
    await incidencesApi.list()
      .then(
        response => {
          commit('setSelectMaintenances', response.data.data)
        },
        () => {
          commit('notifications', { title: 'Error en la búsqueda de incidencias.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async searchMaintenances({ commit }, {
    search = '',
  }) {
    commit('app/loading', true, { root: true })
    await incidencesApi.list(1, 9999, search)
      .then(
        response => {
          commit('setlistMaintenances', response.data.data)
          commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error en la búsqueda de incidencias.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getListIncidences({ commit }, {
    page, per_page, search, id_client, asset_id, project_id, maintenance_id, date_ini, date_fin
  }) {
    commit('app/loading', true, { root: true })
    await incidencesApi.list(page, per_page, search, id_client, asset_id, project_id, maintenance_id, date_ini, date_fin)
      .then((response) => {
          commit('setItems', response.data.data)
          commit('setTotalItems', response.data.meta.total[1])
          commit('app/loading', false, { root: true })
      })
      .catch((error) => {
        commit('app/loading', false, { root: true })
        commit('notifications', { title: 'Error en la búsqueda de incidencias.', variant: 'danger' }, { root: true })
        commit('setShowNotifications', true, { root: true })
      })
  },
  async getIncidence({ commit }, id) {
    commit('app/loading', true, { root: true })
    await incidencesApi.get(id)
      .then(
        response => {
          commit('setIncidence', response.data.data)
          console.log('incidencia', response.data.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener la incidencia', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  edit({ commit }, { id, incidence }) {
    commit('app/loading', true, { root: true })
    incidencesApi.edit(id, incidence)
      .then(
        response => {
          commit('notifications', { title: 'Incidencia actualizado con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          // router.push({ name: 'incidence' })
          router.go(-1)
          // commit('app/loading', false, { root: true })
          // if (store.getters['auth/getRole'] === 'user' || store.getters['auth/getRole'] === 'admin') {
          //   router.push({ name: 'viewMaintenance', params: { id: response.data.data.id } })
          // } else {
          //   router.push({ name: 'maintenances' })
          // }

        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al actualizar el incidencia.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  create({ commit }, { incidence }) {
    commit('app/loading', true, { root: true })
    incidencesApi.create(incidence)
      .then(
        response => {
          commit('notifications', { title: 'Incidencia creada con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          // router.push({ name: 'incidence' })
          router.go(-1)
          // commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al crear incidencia.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  add({ commit }, { incidence }) {
    commit('app/loading', true, { root: true })
    incidencesApi.create(incidence)
      .then(
        response => {
          commit('notifications', { title: 'Incidencia creada con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          // router.push({ name: 'incidence' })
          location.reload()
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al crear incidencia.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    await incidencesApi.delete(id)
      .then(
        () => {
          commit('notifications', { title: 'Incidencia eliminada con éxito.', variant: 'success' }, { root: true })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al eliminar el incidencia.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async close({ commit }, { id, params }) {
    commit('app/loading', true, { root: true })
    await incidencesApi.close(id, params)
      .then(
        response => {
          commit('notifications', { title: 'Actualizado.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al cerrar la incidencia.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )

  },
}

const getters = {
  getListIncidences: state => { return state.items },
  getIncidence: state => { return state.incidence },
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
