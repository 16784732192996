import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/clients'

export default {
  list(page, per_page, province, incidence, active, search = '') {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      page,
      per_page,
      search,
      province,
      incidence,
      active,
    })
  },
  edit(id, client) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, client)
  },
  create(client) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, client)
  },
  getClient(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  delete(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
}
