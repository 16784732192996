import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/alerts'

export default {

  list(page, per_page, search, type_id, client_id) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      page,
      per_page,
      search,
      type_id,
      client_id
    })
  },
  edit(id, incidence) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, incidence)
  },
  create(incidence) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, incidence)
  },
  get(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  tipos() {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/types/list`)
  }

}
