import axios from 'axios'
import { config } from '@/shared/app.config'

const RESOURCE_NAME = '/documentcategories'

export default {
  list(params) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, params)
  },
  async setOrder(rows) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/reorder`, { rows: rows });
  }
  // edit(id, document) {
  //   return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, document)
  // },
  // create(document) {
  //   return axios.post(`${config.webApiBase + RESOURCE_NAME}`, document)
  // },
  // delete(id) {
  //   return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  // },
  // getDocument(id) {
  //   return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  // },
}
