import axios from 'axios'
import { config } from '../shared/app.config'

export default {
  getHeader(){
    return axios.get(`https://tecnitexfire.com/hola.php`, {
      headers:{'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Headers': 'Authorization, X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Allow-Request-Method',
        'Access-Control-Allow-Methods' : 'GET, POST, OPTIONS, PUT, DELETE',
        'Allow': 'GET, POST, OPTIONS, PUT, DELETE'}
    })
  },
  status(type) {
    return axios.post(`${config.webApiBase}/status/list`, {
      type,
    })
  },
  types(type) {
    return axios.post(`${config.webApiBase}/types/list`, {
      type,
    })
  },
  roles() {
    return axios.get(`${config.webApiBase}/roles/list`)
  },
  dashboard_assets(client = '', group = '') {
    return axios.post(`${config.webApiBase}/dashboard/assets`, {
      id_client: client,
      group_id: group,
    })
  },
  dashboard_comun(client = '') {
    return axios.post(`${config.webApiBase}/dashboard/comun`, {
      id_client: client,
    })
  },
}
