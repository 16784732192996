import maintenancesApi from '@/api/maintenances-api'
import generalApi from '../../api/general-api'

import router from '@/router'
import store from '@/store'

const state = {
  maintenance: {},
  currentMaintenance: null,
  showModalMaintenances: false,
  listMaintenances: [],
  selectMaintenances: [],
  items: [],
  totalItems: 0,
  selectStatus: []
}

const mutations = {
  recoverCurrentMaintenance(state) {
    const recoveredCurrentMaintenance = JSON.parse(localStorage.getItem('currentMaintenance'))
    if (!recoveredCurrentMaintenance) {
      state.currentMaintenance = null
    } else {
      state.currentMaintenance = recoveredCurrentMaintenance
    }
  },
  setShowModalMaintenances(state, val) {
    state.showModalMaintenances = val
  },
  setlistMaintenances(state, val) {
    state.listMaintenances = val
  },
  setSelectMaintenances(state, val) {
    state.selectMaintenances = val
  },
  setCurrentMaintenance(state, val) {
    state.currentMaintenance = val
    localStorage.setItem('currentMaintenance', JSON.stringify(state.currentMaintenance))
    location.reload()
  },
  removeCurrentMaintenance(state) {
    state.currentMaintenance = null
    localStorage.removeItem('currentMaintenance')
  },
  setItems(state, val) {
    state.items = val
  },
  setMaintenance(state, val) {
    state.maintenance = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
  setStatusList(state, val) {
    state.selectStatus = val
  }
}

const actions = {
  async selectMaintenances({ commit }) {
    await maintenancesApi.list()
      .then(
        response => {
          commit('setSelectMaintenances', response.data.data)
        },
        () => {
          commit('notifications', { title: 'Error en la búsqueda de mantenimientos.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async searchMaintenances({ commit }, {
    search = '',
  }) {
    commit('app/loading', true, { root: true })
    await maintenancesApi.list(1, 9999, search)
      .then(
        response => {
          commit('setlistMaintenances', response.data.data)
          commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error en la búsqueda de mantenimientos.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getListMaintenances({ commit }, {
    page, per_page, search, id_client, status_id, project_id, asset_id, date_ini, date_fin
  }) {
    commit('app/loading', true, { root: true })
    await maintenancesApi.list(page, per_page, search, id_client, status_id, project_id, asset_id, date_ini, date_fin)
      .then(
        response => {
          commit('setItems', response.data.data)
          commit('setTotalItems', response.data.meta.total[1])
          commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error en la búsqueda de maintenances.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getMaintenance({ commit }, id) {
    commit('app/loading', true, { root: true })
    await maintenancesApi.getMaintenance(id)
      .then(
        response => {
          commit('setMaintenance', response.data.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener el mantenimiento.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  edit({ commit }, { id, maintenance }) {
    commit('app/loading', true, { root: true })
    maintenancesApi.edit(id, maintenance)
      .then(
        response => {
          commit('notifications', { title: 'Mantenimiento actualizado con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          router.go(-1)
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al actualizar el mantenimiento.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  create({ commit }, { maintenance }) {
    commit('app/loading', true, { root: true })
    maintenancesApi.create(maintenance)
      .then(
        response => {
          commit('notifications', { title: 'Mantenimiento creado con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          router.push({ name: 'maintenances' })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al crear mantenimiento.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    maintenancesApi.delete(id)
      .then(
        () => {
          commit('notifications', { title: 'Mantenimiento eliminado con éxito.', variant: 'success' }, { root: true })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al eliminar el mantenimiento.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getSelectStatus({ commit }, {
    type
  }) {
    // commit('app/loading', true, { root: true })
    await generalApi.status(
      type)
      .then(
        response => {
          commit('setStatusList', response.data)
          // commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error en la búsqueda de estados.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async changeApto({ commit }, { id, maintenance }) {
    commit('app/loading', true, { root: true })
    maintenancesApi.changeApto(id, maintenance)
      .then(
        response => {
          commit('notifications', { title: 'Actualizado.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al actualizar el mantenimiento.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async review({ commit }, { id, maintenance }) {
    commit('app/loading', true, { root: true })
    maintenancesApi.review(id, maintenance)
      .then(
        response => {
          commit('notifications', { title: 'Actualizado.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          router.go(0)
          commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al actualizar el mantenimiento.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async init({ commit }, { id }) {
    commit('app/loading', true, { root: true })
    maintenancesApi.init(id)
      .then(
        response => {
          commit('notifications', { title: 'Actualizado.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          location.reload()
          // commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al actualizar el mantenimiento.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        }
      )
  },
  async close({ commit }, { id, params }) {
    commit('app/loading', true, { root: true })
    maintenancesApi.close(id, params)
      .then(
        response => {
          commit('notifications', { title: 'Actualizado.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          location.reload()
          // commit('app/loading', false, { root: true })
        },
        error => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al actualizar el mantenimiento.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        }
      )
  }
}

const getters = {
  getCurrentMaintenance: state => { return state.currentMaintenance },
  getSelectMaintenances: state => { return state.selectMaintenances },
  getListMaintenances: state => { return state.listMaintenances },
  getListMaintenancesFrecuentes: state => { return state.listMaintenancesFrecuentes },
  getShowModalMaintenances: state => { return state.showModalMaintenances },
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
  getMaintenance: state => { return state.maintenance },
  getSelectStatus: state => state.selectStatus

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
