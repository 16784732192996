import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/addresses'

export default {
  edit(id, address) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, address)
  },
  create(address) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, address)
  },
  delete(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  getAddress(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  list(page, per_page, search, client_id, type) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      page,
      per_page,
      search,
      client_id,
      type,
    })
  },
}
