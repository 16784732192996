import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import documents from '@/store/documents'
import docCategories from '@/store/documentCategories'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import notifications from './notifications'
import auth from './auth'
import clients from './clients'
import products from './products'
import projects from './projects'
import locations from './locations'
import orders from './orders'
import users from './users'
import categories from './categories'
import addresses from './addresses'
import payments from './payments'
import provinces from './provinces'
import steps from './steps'
import config from './config'
import languages from './languages'
import assets from './assets'
import maintenances from './maintenances'
import incidences from './incidences'
import alerts from './alerts'
import countries from './countries'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    notifications,
    auth,
    clients,
    orders,
    products,
    projects,
    locations,
    users,
    categories,
    config,
    addresses,
    payments,
    provinces,
    steps,
    languages,
    assets,
    maintenances,
    incidences,
    alerts,
    documents,
    docCategories,
    countries
  },
  strict: process.env.DEV,
})
