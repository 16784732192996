import countriesApi from "@/api/countries-api"

const state = {
  countries: []
}

const mutations = {
  setCountries(state, val) {
    state.countries = val
  }
}

const actions = {
  async getListCountries({ commit }, {
    page, per_page, search = '', orderBy,
  }) {
    const response = await countriesApi.list(page, per_page, search, orderBy)
    commit('setCountries', response.data.data)  
  }
}

const getters = {
  getCountries: (state) => state.countries
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
