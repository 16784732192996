import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/users'

export default {
  edit(id, user) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, user)
  },
  create(user) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, user)
  },
  createnew(user) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/createnew`, user)
  },
  delete(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  getUser(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  resetPassword(id, oldPassword, newPassword) {
    return axios.put(`${config.webApiBase + RESOURCE_NAME}/${id}/password-change`, {
      oldPassword,
      newPassword,
    })
  },
  list(page, per_page, search, roles, client_id, date_ini, date_fin) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      page,
      per_page,
      search,
      roles,
      client_id,
      date_ini,
      date_fin
    })
  },
}
