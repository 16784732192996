import DocCatApi from '@/api/documentCategories-api'

const state = {
  docCategories: [],
  totalDocCategories: 0,
}

const mutations = {
  setDocCategories(_, docCategories) {
    state.docCategories = docCategories
  },
  setTotalDocCategories(_, totalDocCategories) {
    state.totalDocCategories = totalDocCategories
  },
}

const actions = {
  async listDocCategories({ commit }, parameters = {}) {
    try {
      const response = await DocCatApi.list(parameters)
      commit('setDocCategories', response.data.data)
      commit('setTotalDocCategories', response.data.meta.total[1])
    } catch (error) {
      commit('notifications', { title: 'Error al obtener los documentos.', variant: 'danger' }, { root: true })
      commit('setShowNotifications', true, { root: true })
    }
  },
  async setOrder({ commit }, rows) {
    try {
      const response = await DocCatApi.setOrder(rows)
      commit('notifications', { title: 'Producto ordenado con éxito.', variant: 'success' }, { root: true })
    } catch (error) {
      commit('notifications', { title: 'Error al ordenar producto.', variant: 'danger' }, { root: true })
    }
    commit('setShowNotifications', true, { root: true })
  }
  // async createDocument({ commit }, document) {
  //   try {
  //     await DocCatApi.create(document)
  //     commit('notifications', { title: 'Documento adjuntado con éxito.', variant: 'success' }, { root: true })
  //   } catch (error) {
  //     console.error(error)
  //     commit('notifications', { title: 'Error al adjuntar el documento.', variant: 'danger' }, { root: true })
  //   }

  //   commit('setShowNotifications', true, { root: true })
  // },
  // async delete({ commit }, id) {
  //   try {
  //     await DocCatApi.delete(id)
  //     commit('notifications', { title: 'Documento eliminado con éxito.', variant: 'success' }, { root: true })
  //   } catch (error) {
  //     console.error(error)
  //     commit('notifications', { title: 'Error al eliminar el documento.', variant: 'danger' }, { root: true })
  //   }

  //   commit('setShowNotifications', true, { root: true })
  // },
}

const getters = {
  getDocCategories: () => state.docCategories,
  getTotalDocCategories: () => state.totalDocCategories,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
