import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/orders'
const RESOURCE_NAME_LINES = '/orderlines'

export default {
  list(page, per_page, search = '', user_id, status_id, client_id, orderBy) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      page,
      per_page,
      search,
      user_id,
      status_id,
      client_id,
      orderBy,
    })
  },
  changeArchives(order) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/changeArchives`, order)
  },
  create(order) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, order)
  },
  getOrder(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  checkControlBox(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/check/controlbox/${id}`)
  },
  changeStatus(id, status, plannedDeliveryDate, created_at, url_seguimiento) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/changestatus`, {
      order_id: id,
      status,
      planned_delivery_date: plannedDeliveryDate,
      url_seguimiento,
      created_at,
    })
  },
  delete(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  editLine(id, client) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME_LINES}/${id}`, client)
  },
  createLine(orderLine) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME_LINES}`, orderLine)
  },
  getPriceShipping(order, address) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/calculatesendprice`, {
      order_id: order,
      address_id: address,
    })
  },
  finish(order, address, install) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/finish`, {
      order_id: order,
      address_id: address,
      install,
    })
  },
  saveTransport(order, address) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/saveTransport`, {
      order_id: order,
      address_id: address,
    })
  },
  saveFacilityTransport(order, address) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/saveInstalation`, {
      order_id: order,
      address_id: address,
    })
  },
  getOrderLine(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME_LINES}/${id}`)
  },
  deleteLine(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME_LINES}/${id}`)
  },
  savePriceTransport(order, price) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/price/changeTransport`, {
      order_id: order,
      price: price,
    })
  },
  savePackaging(order, price, type) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/change/packaging/super`, {
      order_id: order,
      price: price,
      type: type,
    })
  },
  savePriceInstallation(order, price) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/price/installation`, {
      order_id: order,
      price: price,
    })
  },
  savePriceDiscount(order, discount) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/price/discount`, {
      order_id: order,
      discount: discount,
    })
  },
  finishRevision(order) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/revision/finish`, {
      order_id: order,
    })
  },
  changePackaging(order, type) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/change/packaging`, {
      order_id: order,
      type,
    })
  }
}
