import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/incidences'

export default {

  list(page, per_page, search, id_client, asset_id, project_id, maintenance_id, date_ini, date_fin) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      page,
      per_page,
      search,
      id_client,
      project_id,
      maintenance_id,
      asset_id,
      // orderBy: { "column": "id", "type": "desc" }
      date_ini,
      date_fin
    })
  },
  edit(id, incidence) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, incidence)
  },
  create(incidence) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, incidence)
  },
  get(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  close(id, params) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/close/${id}`, params)
  },
  delete(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
}
