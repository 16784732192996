import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import {
  isUserLoggedIn, getUserData, getUserRole, getHomeRouteForLoggedInUser,
} from '@/auth/utils'
import config from '@/store/config'
import i18n from '@/libs/i18n'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'login' } },
    {
      path: '/rater',
      meta: {
        needLogin: true,
      },
      beforeEnter: (to, from, next) => {
        store.dispatch('auth/checkToken')
        next()
      },
      component: () => import('@/layouts/full/LayoutFull.vue'),
      children: [
        {
          path: 'steps',
          name: 'steps',
          component: () => import('@/views/steps/Control.vue'),
        },
      ],
    },
    {
      path: '/admin',
      meta: {
        needLogin: true,
      },
      beforeEnter: (to, from, next) => {
        store.dispatch('auth/checkToken')
        next()
      },
      component: () => import('@/layouts/full/LayoutFull.vue'),
      children: [
        {
          path: 'profile',
          name: 'profile',
          component: () => import('@/views/account/AccountPage.vue'),
        }, 
        // obras
        {
          path: 'projects',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin', 'tec', 'tec_client'],
          },
          name: 'projects',
          component: () => import('@/views/projects/List.vue'),
        },
        {
          path: 'projects/edit/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin'],
          },
          name: 'editProject',
          component: () => import('@/views/projects/Edit.vue'),
        },
        {
          path: 'projects/view/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin', 'tec', 'tec_client'],
          },
          name: 'viewProject',
          component: () => import('@/views/projects/View.vue'),
        },
        {
          path: 'projects/create/:client_param?',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin'],
          },
          name: 'createProject',
          component: () => import('@/views/projects/Create.vue'),
        }, 
        //productos
        {
          path: 'productsList/:category_id?',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin', 'user', 'documental'],
          },
          name: 'products',
          component: () => import('@/views/products/List.vue'),
        },
        {
          path: 'products/edit/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin'],
          },
          name: 'editProduct',
          component: () => import('@/views/products/Edit.vue'),
        },
        {
          path: 'products/create',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin'],
          },
          name: 'createProduct',
          component: () => import('@/views/products/Create.vue'),
        },
        {
          path: 'products/view/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin', 'user', 'documental'],
          },
          name: 'viewProduct',
          component: () => import('@/views/products/View.vue'),
        }, 
        // pagos
        {
          path: 'payments',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin'],
          },
          name: 'payments',
          component: () => import('@/views/payments/List.vue'),
        },
        {
          path: 'payments/edit/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin'],
          },
          name: 'editPayment',
          component: () => import('@/views/payments/Edit.vue'),
        },
        {
          path: 'payments/create',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin'],
          },
          name: 'createPayment',
          component: () => import('@/views/payments/Create.vue'),
        },
        {
          path: 'company-types',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin'],
          },
          name: 'companyTypes',
          component: () => import('@/views/company-types/List.vue'),
        },
        {
          path: 'provinces',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin'],
          },
          name: 'provinces',
          component: () => import('@/views/provinces/List.vue'),
        },
        {
          path: 'provinces/edit/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin'],
          },
          name: 'editProvince',
          component: () => import('@/views/provinces/Edit.vue'),
        },
        {
          path: 'users',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin'],
          },
          name: 'users',
          component: () => import('@/views/users/List.vue'),
        },
        {
          path: 'internals',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin'],
          },
          name: 'internals',
          component: () => import('@/views/users/List.vue'),
        },
        {
          path: 'users/edit/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin'],
          },
          name: 'editUser',
          component: () => import('@/views/users/Edit.vue'),
        },
        {
          path: 'users/view/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin'],
          },
          name: 'viewUser',
          component: () => import('@/views/users/View.vue'),
        },
        {
          path: 'internals/create',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin'],
          },
          name: 'createInternals',
          component: () => import('@/views/users/Create.vue'),
        },
        {
          path: 'users/create',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin'],
          },
          name: 'createUser',
          component: () => import('@/views/users/Create.vue'),
        },
        {
          path: 'categories/list/:id?',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin', 'user', 'documental'],
          },
          name: 'categoriesList',
          component: () => import('@/views/categories/ListCat.vue'),
        },
        {
          path: 'categories',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin', 'user'],
          },
          name: 'categories',
          component: () => import('@/views/categories/List.vue'),
        },
        {
          path: 'categories/edit/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin', 'user'],
          },
          name: 'editCategory',
          component: () => import('@/views/categories/Edit.vue'),
        },
        {
          path: 'categories/create',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin', 'admin_cliente'],
          },
          name: 'createCategory',
          component: () => import('@/views/categories/Create.vue'),
        },
        // Clients
        {
          path: 'clients',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin'],
          },
          name: 'clients',
          component: () => import('@/views/clients/List.vue'),
        },
        {
          path: 'clients/view/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin', 'user'],
          },
          name: 'viewClient',
          component: () => import('@/views/clients/View.vue'),
        },
        {
          beforeEnter: (to, from, next) => {
            const userData = getUserData()
            next({ name: 'viewClient', params: { id: userData.clients[0].id } })
          },
          path: 'clients/current',
          meta: {
            needLogin: true,
            roleAllow: ['user', 'admin'],
          },
          name: 'currentClient',
        },
        {
          path: 'clients/edit/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin', 'user'],
          },
          name: 'editClient',
          component: () => import('@/views/clients/Edit.vue'),
        },
        {
          path: 'clients/create',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin', 'user'],
          },
          name: 'createClient',
          component: () => import('@/views/clients/Create.vue'),
        },
        {
          path: 'locations/create/:project_id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin'],
          },
          name: 'createLocation',
          component: () => import('@/views/locations/Create.vue'),
        },
        {
          path: 'locations/view/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin'],
          },
          name: 'viewLocation',
          component: () => import('@/views/locations/View.vue'),
        },
        {
          path: 'locations/edit/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin'],
          },
          name: 'editLocation',
          component: () => import('@/views/locations/Edit.vue'),
        }, 
        // assets (equipos)
        {
          path: 'assets',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'tec', 'admin', 'user', 'tec_client'],
          },
          name: 'assets',
          component: () => import('@/views/assets/List.vue'),
        },
        {
          path: 'assets/view/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'tec', 'admin', 'user', 'tec_client'],
          },
          name: 'viewAsset',
          component: () => import('@/views/assets/View.vue'),
        },
        {
          path: 'assets/create',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin'],
          },
          name: 'AddAsset',
          component: () => import('@/views/assets/Add.vue'),
        },
        {
          path: 'assets/create/:project_id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin'],
          },
          name: 'createAsset',
          component: () => import('@/views/assets/Create.vue'),
        },
        {
          path: 'assets/edit/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin'],
          },
          name: 'editAsset',
          component: () => import('@/views/assets/Edit.vue'),
        }, 
        //orders
        {
          path: 'orders',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin', 'user'],
          },
          name: 'orders',
          component: () => import('@/views/orders/List.vue'),
        },
        {
          path: 'orders/view/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin', 'user'],
          },
          name: 'viewOrders',
          component: () => import('@/views/orders/View.vue'),
        },
        {
          path: 'orders/edit/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin'],
          },
          name: 'editOrders',
          component: () => import('@/views/orders/Edit.vue'),
        }, 
        // maintenances
        {
          path: 'maintenances',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'tec', 'admin', 'user', 'tec_client'],
          },
          name: 'maintenances',
          component: () => import('@/views/maintenances/List.vue'),
        },
        {
          path: 'maintenances/view/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'tec', 'admin', 'user', 'tec_client'],
          },
          name: 'viewMaintenance',
          component: () => import('@/views/maintenances/View.vue'),
        },
        {
          path: 'maintenances/create',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin'],
          },
          name: 'createMaintenance',
          component: () => import('@/views/maintenances/Create.vue'),
        },
        {
          path: 'maintenances/edit/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin'],
          },
          name: 'editMaintenance',
          component: () => import('@/views/maintenances/Edit.vue'),
        },
        //incidences
        {
          path: 'incidences',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin', 'tec', 'tec_client'],
          },
          name: 'incidences',
          component: () => import('@/views/incidences/List.vue'),
        },
        {
          path: 'incidences/view/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin', 'tec', 'tec_client'],
          },
          name: 'viewIncidence',
          component: () => import('@/views/incidences/View.vue'),
        },
        {
          path: 'incidences/create/:maintenance_id?',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'tec'],
          },
          name: 'createIncidence',
          component: () => import('@/views/incidences/Create.vue'),
        },
        {
          path: 'incidences/add/:asset_id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'tec'],
          },
          name: 'addIncidence',
          component: () => import('@/views/incidences/Add.vue'),
        },
        {
          path: 'incidences/edit/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'tec'],
          },
          name: 'editIncidence',
          component: () => import('@/views/incidences/Edit.vue'),
        },
        {
          path: 'config',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin'],
          },
          name: 'config',
          component: () => import('@/views/configuracion/Configuracion.vue'),
        },
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/Register.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/reset-password/:token/:email',
      name: 'reset-password',
      component: () => import('@/views/users/ResetPassword.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-401',
      name: 'error-401',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (to.meta.needLogin) {
    if (!isLoggedIn) { return next({ name: 'login' }) }
  }

  const lang = to.query?.lang
  if (lang) {
    i18n.locale = lang
    store.commit('languages/setCurrentLanguage', lang)
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn === true && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData))
  }
  if (to.meta.roleAllow) {
    const role = getUserRole()
    if (!to.meta.roleAllow.includes(role)) {
      next({ name: 'error-401' })
    }
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(to => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
