import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/calculators'

export default {
  uploadCalculator(calculator) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/import`, calculator)
  },
  getCalculator(calculator) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/calculate`, calculator)
  },
  listCalculator(page, per_page, search = '', type = '', product_id = '') {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      page,
      per_page,
      search,
      type,
      product_id,
    })
  },
  send(form) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/sendform`, form)
  },

}
