import DocumentsApi from '@/api/documents-api'

const state = {
  documents: [],
  totalDocuments: 0,
  //
  comercialDocs: [],
  totalComercialDocs: 0,
  //
  techDocs: [],
  totalTechDocs: 0,
  //
  maintenancesDocs: [],
  totalMaintenancesDocs: 0,
  //
  administrativesDocs: [],
  totalAdministrativesDocs: 0,
}

const mutations = {
  setDocuments(_, documents) {
    state.documents = documents
  },
  setTotalDocuments(_, totalDocuments) {
    state.totalDocuments = totalDocuments
  },
  setComercialDocs(_, comercialDocs) {
    state.comercialDocs = comercialDocs
  },
  setTotalComercialDocs(_, totalComercialDocs) {
    state.totalComercialDocs = totalComercialDocs
  },
  setTechDocs(_, techDocs) {
    state.techDocs = techDocs
  },
  setTotalTechDocs(_, totalTechDocs) {
    state.totalTechDocs = totalTechDocs
  },
  setMaintenancesDocs(_, maintenancesDocs) {
    state.maintenancesDocs = maintenancesDocs
  },
  setTotalMaintenancesDocs(_, totalMaintenancesDocs) {
    state.totalMaintenancesDocs = totalMaintenancesDocs
  },
  setAdministrativesDocs(_, administrativesDocs) {
    state.administrativesDocs = administrativesDocs
  },
  setTotalAdministrativesDocs(_, totalAdministrativesDocs) {
    state.totalAdministrativesDocs = totalAdministrativesDocs
  },
}

const actions = {
  async getDocuments({ commit }, parameters = {}) {
    try {
      const response = await DocumentsApi.list(parameters)
      commit('setDocuments', response.data.data)
      commit('setTotalDocuments', response.data.meta.total[1])
    } catch (error) {
      commit('notifications', { title: 'Error al obtener los documentos.', variant: 'danger' }, { root: true })
      commit('setShowNotifications', true, { root: true })
    }
  },
  async getComercialDocs({ commit }, parameters = {}) {
    try {
      const response = await DocumentsApi.list(parameters)
      commit('setComercialDocs', response.data.data)
      commit('setTotalComercialDocs', response.data.meta.total[1])
    } catch (error) {
      commit('notifications', { title: 'Error al obtener los documentos.', variant: 'danger' }, { root: true })
      commit('setShowNotifications', true, { root: true })
    }
  },
  async getTechDocs({ commit }, parameters = {}) {
    try {
      const response = await DocumentsApi.list(parameters)
      commit('setTechDocs', response.data.data)
      commit('setTotalTechDocs', response.data.meta.total[1])
    } catch (error) {
      commit('notifications', { title: 'Error al obtener los documentos.', variant: 'danger' }, { root: true })
      commit('setShowNotifications', true, { root: true })
    }
  },
  async getMaintenancesDocs({ commit }, parameters = {}) {
    try {
      const response = await DocumentsApi.list(parameters)
      commit('setMaintenancesDocs', response.data.data)
      commit('setTotalMaintenancesDocs', response.data.meta.total[1])
    } catch (error) {
      commit('notifications', { title: 'Error al obtener los documentos.', variant: 'danger' }, { root: true })
      commit('setShowNotifications', true, { root: true })
    }
  },
  async getAdministrativeDocs({ commit }, parameters = {}) {
    try {
      const response = await DocumentsApi.list(parameters)
      commit('setAdministrativesDocs', response.data.data)
      commit('setTotalAdministrativesDocs', response.data.meta.total[1])
    } catch (error) {
      commit('notifications', { title: 'Error al obtener los documentos.', variant: 'danger' }, { root: true })
      commit('setShowNotifications', true, { root: true })
    }
  },
  async createDocument({ commit }, document) {
    try {
      await DocumentsApi.create(document)
      commit('notifications', { title: 'Documento adjuntado con éxito.', variant: 'success' }, { root: true })
    } catch (error) {
      console.error(error)
      commit('notifications', { title: 'Error al adjuntar el documento.', variant: 'danger' }, { root: true })
    }

    commit('setShowNotifications', true, { root: true })
  },
  async delete({ commit }, id) {
    try {
      await DocumentsApi.delete(id)
      commit('notifications', { title: 'Documento eliminado con éxito.', variant: 'success' }, { root: true })
    } catch (error) {
      console.error(error)
      commit('notifications', { title: 'Error al eliminar el documento.', variant: 'danger' }, { root: true })
    }

    commit('setShowNotifications', true, { root: true })
  },
  async edit({ commit }, { id, document }) {
    commit('app/loading', true, { root: true })
    console.log('api edit', document);
    await DocumentsApi.edit(id, document)
      .then(
        () => {
          commit('notifications', { title: 'Documento actualizado.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          location.reload()
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al actualizar documento.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async setOrder({ commit }, rows) {
    try {
      const response = await DocumentsApi.setOrder(rows)
      commit('notifications', { title: 'Producto ordenado con éxito.', variant: 'success' }, { root: true })
    } catch (error) {
      commit('notifications', { title: 'Error al ordenar producto.', variant: 'danger' }, { root: true })
    }
    commit('setShowNotifications', true, { root: true })
  }
}

const getters = {
  getDocuments: () => state.documents,
  getTotalDocuments: () => state.totalDocuments,
  getComercialDocs: () => state.comercialDocs,
  getTotalComercialDocs: () => state.totalComercialDocs,
  getTechDocs: () => state.techDocs,
  getTotalTechDocs: () => state.totalTechDocs,
  getMaintenancesDocs: () => state.maintenancesDocs,
  getAdministrativeDocs: () => state.administrativesDocs,
  getTotalMaintenancesDocs: () => state.totalMaintenancesDocs,
  getTotalAdministrativeDocs: () => state.totalAdministrativesDocs,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
